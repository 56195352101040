@import './../../../../scss/theme-bootstrap';

.site-header__page-nav {
  background: $color-white;
  overflow: hidden;
  position: relative;
  height: 0;
  @include breakpoint($medium-up) {
    top: 0;
    overflow: hidden;
    width: 100%;
  }
  &.visible {
    top: 0;
    border-bottom: $border;
    height: auto;
    @include breakpoint($medium-up) {
      border-bottom: none;
    }
    .site-header__page-nav {
      &--pc,
      &--mobile {
        transition: all 0.5s ease 0s;
        margin-top: 0;
      }
    }
  }
  &--pc {
    @include breakpoint($medium-up) {
      padding: 5px 0;
      width: 100%;
      background: $color-white;
      margin-top: -72px;
      text-align: center;
    }
    li {
      @include breakpoint($medium-up) {
        display: inline;
      }
      a {
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 5px);
          @include swap_direction(margin, 5px);
          text-decoration: none;
          border: $border;
          display: inline-block;
          width: 148px;
        }
        &:hover {
          @include breakpoint($medium-up) {
            color: $color-white;
            background: $color-black;
          }
        }
      }
    }
  }
  &--mobile {
    @include swap_direction(padding, 10px);
    width: 100%;
    color: $color-black;
    font-weight: bold;
    font-size: 20px;
    margin-top: -140px;
  }
  &-options {
    @include swap_direction(margin, 10px);
    font-size: 18px;
    a {
      @include swap_direction(padding, 5px 10px);
      text-decoration: none;
      display: block;
    }
  }
  &--accordion {
    position: relative;
    cursor: pointer;
  }
  &-title {
    float: #{$ldirection};
    width: 90%;
  }
  &-close {
    top: 60px;
    .nav-icon-close {
      display: none;
    }
    &.nav-open {
      .nav-icon-close {
        display: block;
      }
      .nav-icon-down {
        display: none;
      }
    }
  }
}
